<template>
  <div class="wrap">
    <div class="top_title">
      <img :src="require('@/assets/Img/login/login_title.png')" alt="">
    </div>
    <div class="form_bar">
      <LoginType
        v-model="loginType"
        v-if="!verConfig.onlyEmailLogin && !verConfig.onlyPhoneLogin"
      />
      <div
        class="inputs"
        :style="
          (verConfig.onlyEmailLogin || verConfig.onlyPhoneLogin) && {
            marginTop: 0,
          }
        "
      >
        <div
          v-for="(item, index) in formList"
          :key="index"
          class="border-bottom-calss"
          v-show="!(verConfig.banUserName && item.key === 'username')"
        >
          <i class="iconfont" :class="item.icon"></i>
          <div
            :style="{
              'justify-content': item.key === 'userName' ? '' : 'space-between',
            }"
          >
            <span
              v-if="
                item.key === 'phone' &&
                verConfig.areaCode &&
                loginType == 'phone'
              "
              style="
                padding-left: 15px;
                padding-right: 12px;
                border-right: 1px solid var(--sizeColor);
                border-left: 1px solid var(--sizeColor);
                color: var(--sizeColor);
                display: flex;
              "
              >+
              <AreaCodePicker v-model="areaCode" />
            </span>
            <CurrencyPicker
              v-model="form[item.key]"
              v-if="item.inputType === 'selectUnit'"
              @onConfirm="haddleCurrencyConfirm"
            >
              <input
                :type="item.type"
                :placeholder="item.label"
                :maxlength="item.length"
                v-model="selectTittle"
                readonly
              />
            </CurrencyPicker>
            <input
              :type="item.type"
              v-else-if="
                (item.onlyPhone && loginType == 'phone') || !item.onlyPhone
              "
              :placeholder="item.label"
              :maxlength="item.length"
              v-model="form[item.key]"
            />
            <!-- <input
              :type="item.type"
              :placeholder="item.placeholder"
              :maxlength="item.length"
              v-model="form[item.key]"
              :adjust-position="true"
              @focus="handleOnfocus"
              @blur="handleOnblur"
            /> -->
            <!-- <i
              class="iconfont pwd_icons"
              v-if="item.key === 'password' || item.key === 'againPassword'"
              :class="item.flag === true ? 'icon-yanjing' : 'icon-yanjing-bi'"
              @click="handleLookPass"
            ></i> -->
          </div>
        </div>
      </div>
      <div class="user_agreement" v-if="verConfig.registerHasRead">
        <van-checkbox
          v-model="userAgreementChecked"
          shape="round"
          class="checkbox2"
          checked-color="#00551e"
        >
        </van-checkbox>
        <router-link to="/UserAgreement" class="link">{{
          $t("login.userAgreement")
        }}</router-link>
      </div>
      <div class="btn_login" @click="handleConfirm" :style="theme == 'light' ? {color: 'var(--btnsizeColor)'}:{}">
        {{ $t("Login.Forgot.confirm") }}
      </div>
      <div class="btn_registe" @click="handleClose">
        {{ $t("Login.Forgot.goBack") }}
      </div>
    </div>
    <div class="lan_bar" v-if="verConfig.loginSetLans">
      <SetLans v-model="areaCode" />
    </div>
    <LoadingRE :show="LoadingShow" />
    <Popup :show="true" position="bottom">
      <Picker
        show-toolbar
        :columns="[JsonData]"
        :value-key="'zh'"
        :confirm-button-text="$t('Button.confirm')"
        :cancel-button-text="$t('Button.cancle')"
      />
    </Popup>
  </div>
</template>

<script>
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  NavBar,
  ActionSheet,
  Button,
  Toast,
  Checkbox,
  Popup,
  Picker,
  Field,
} from "vant";

import JsonData from "@/components/areaCodePicker/data.json";
import AreaCodePicker from "@/components/areaCodePicker";
import { mapGetters } from "vuex";
// 设置vant的多语言
import md5 from "js-md5";
import Cookies from "js-cookie";
import { Locale } from "vant";
import enUS from "vant/lib/locale/lang/en-US";
import Modelu from "@/utils/Module";
import antiShake from "@/utils/anti-shake";
import { getNewPhone } from "@/utils/tools";

import { SEND_SMS_CODE, LOGIN } from "@/api";
import LoadingRE from "@/components/LoadingRE";
import LoginType from "@/components/loginType";
import CurrencyPicker from "@/components/currencyPicker";
import SetLans from "@/components/setLans";
import i18n from "@/i18n";
import verConfig from "@/utils/verConfig";
import { getCookieValue,baseEncode } from "@/utils/common";

const phoneItem = {
  label: `${i18n.t("Login.Daftar.phone")}`,
  icon: "icon-wode",
  type: "tel",
  key: "phone",
  flag: false,
  require: true,
  length: 11,
  onlyEmeil: true,
};
const emailItem = {
  label: `${i18n.t("Login.Daftar.email")}`,
  icon: "icon-wode",
  type: "text",
  key: "email",
  flag: false,
  require: true,
  length: 99,
  onlyEmeil: true,
};

const fromidItem = {
  label: `${i18n.t("Login.Daftar.referrer")}`,
  icon: "icon-yaoqing",
  type: "text",
  key: "referrer",
  flag: false,
  require: true,
  length: 99,
};

const _currentLoginType = () => {
  if (verConfig.onlyEmailLogin) {
    return "email";
  }
  return "phone";
};

export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [NavBar.name]: NavBar,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
    LoadingRE,
    Popup,
    Picker,
    AreaCodePicker,
    CurrencyPicker,
    LoginType,
    SetLans,
  },
  data() {
    return {
      userAgreementChecked: this.verConfig.banDefaultRead ? false : true,
      formList: [
        // {
        //   label: `${this.$t('Login.Daftar.area')}`,
        //   type: 'text',
        //   key: 'Area',
        //   length: 5,
        // },
        {
          label: `${this.$t("Login.Daftar.username")}`,
          icon: "icon-yonghuID",
          type: "text",
          key: "username",
          flag: false,
          require: this.verConfig.banUserName ? false : true,
          length: 99,
        },
        phoneItem,
        // {
        //   label: `${this.$t("Login.Daftar.code")}`,
        //   type: "text",
        //   key: "code",
        //   length: 8,
        // },
        // {
        //   label: `${this.$t('Login.Daftar.userName')}`,
        //   type: 'text',
        //   key: 'userName',
        //   length: 99,
        // },
        {
          label: `${this.$t("Login.Daftar.password")}`,
          icon: "icon-jiesuo",
          // type: "password",
          type: "text",
          key: "password",
          flag: false,
          require: true,
          length: 99,
        },
        {
          label: `${this.$t("Login.Daftar.againPassword")}`,
          icon: "icon-jiesuo",
          // type: "password",
          type: "text",
          key: "againPassword",
          flag: false,
          require: true,
          length: 99,
        },
        // {
        //   label: `${this.$t("Login.Daftar.referrer")}`,
        //   type: "text",
        //   key: "referrer",
        //   flag: false,
        //   require: true,
        //   length: 99,
        // },
        // {
        //   label: this.$t("Login.Daftar.payPassword"),
        //   type: "password",
        //   key: "paypass",
        //   flag: true,
        //   require: true,
        //   length: 99,
        // },
      ],
      loginType: _currentLoginType(),
      areaShow: false,
      areaCode: "",
      JsonData,
      country: Cookies.get("language"),
      LoadingShow: false,
      form: {
        phone: "",
        userName: "",
        code: "",
        password: "",
        againPassword: "",
        referrer: "",
      },
      option1: [
        { text: "+1 USA", value: 0 },
        { text: "+1 Canada", value: 1 },
        { text: "+33 France", value: 3 },
        { text: "+39 Italy", value: 4 },
        { text: "+49 Germany", value: 5 },
        { text: "+52 Mexico", value: 6 },
        { text: "+54 Argentina", value: 7 },
        { text: "+55 Brazil", value: 8 },
        { text: "+60 Malaysia", value: 9 },
        { text: "+61 Australian", value: 10 },
        { text: "+62 Indonesia", value: 11 },
        { text: "+66 Thailand", value: 12 },
        { text: "+81 Japan", value: 13 },
        { text: "+84 Viet Nam", value: 14 },
        { text: "+90 turkey", value: 15 },
        { text: "+91 India", value: 16 },
        { text: "+93 Afghanistan", value: 17 },
        { text: "+855 Cambodia", value: 18 },
      ],
      value1: 0,
      isSendCodeClick: true,
      auth_time: 0,
      codeImg: "",
      showflag1: false,
      showflag2: false,
      selectTittle: "",
    };
  },
  created() {
    this.setFromId();
    if (this.verConfig.selectCurrency) {
      this.formList.push({
        label: `${this.$t("Login.Daftar.selectCurrency")}`,
        icon: "icon-fenlei",
        type: "text",
        key: "currency_code",
        inputType: "selectUnit",
        flag: false,
        require: true,
        length: 99,
      });
    }
    try {
      const url = this.$route.fullPath.split("?")[1];
      const kv = url.split("&");
      const list = [];
      for (var i = 0; i < kv.length; i++) {
        const mode = {};
        const value = kv[i].split("=")[1];
        mode.key = value;
        list.push(mode);
      }
      list.forEach((_) => {
        if (_.key) {
          this.form.referrer = _.key;
        }
      });
    } catch (error) {}
    if (this.verConfig.onlyEmailLogin) {
      this.formList.splice(1, 1, emailItem);
    }
    if (this.verConfig.needFromid) {
      this.formList.push(fromidItem);
    }
  },
  computed: {
    // formList() {
    //   return
    // },
    ...mapGetters(["theme"])
  },
  watch: {
    loginType(value) {
      switch (value) {
        case "phone":
          this.formList.splice(1, 1, phoneItem);
          break;
        case "email":
          this.formList.splice(1, 1, emailItem);
          break;
        default:
          break;
      }
    },
  },
  methods: {
    haddleCurrencyConfirm(value, index) {
      this.selectTittle = value.title;
    },
    popdone() {
      // console.log("关闭", this.showPicker);
    },
    /** handle **/
    sendCode: antiShake.debounce(function (e) {
      if (!this.form.phone.length) {
        Toast(this.$t("Hint.phoneNot")); // 手机号/账号不能为空
        return false;
      }
      let form = {
        userphone: this.form.phone,
        must: 1, // this.type === 'reg' ? 1 : 2
        country_code: this.areaCode,
      };
      /**
       * TO DO
       * 这个是短信验证码的，现在暂时不用了
       */
      // if (this.form.Area) {
      //   form.phone = `+${this.form.Area}-${this.form.phone}`
      // } else {
      //   form.phone = `${this.form.phone}`
      // }

      this.LoadingShow = true;
      SEND_SMS_CODE(form)
        .then((r) => {
          this.LoadingShow = false;
          if (r.data.ret === 1) {
            // Toast(this.$t('Hint.success'))
            Toast(r.data.msg);
            this.isSendCodeClick = false;
            this.auth_time = 60;
            let auth_timetimer = setInterval(() => {
              this.auth_time--;
              if (this.auth_time <= 0) {
                this.isSendCodeClick = true;
                clearInterval(auth_timetimer);
              }
            }, 1000);
            // this.codeImg = `data:image/jpg;base64,${r.data.notifyList[0].data.code}`
          } else {
            Toast(r.data.msg);
          }
        })
        .catch((e) => {
          this.LoadingShow = false;
          console.log(e);
        });
    }, 200),
    handleClose() {
      this.form = {
        uesrName: "",
        code: "",
        password: "",
        againPassword: "",
      };
      // this.$router.go(-1)
      this.$router.push({ name: "login" });
    },
    handleConfirm() {
      let list = [
        "phone",
        "code",
        "userName",
        "password",
        "againPassword",
        "referrer",
        "paypass",
        "username",
      ];
      if (this.loginType == "phone") {
        this.form.email = "";
      } else if (this.loginType == "email") {
        this.form.phone = "";
      }
      // let list = ['phone', 'userName', 'password', 'againPassword']
      let count = 0;
      const requireNum = this.formList.filter((item) => item.require).length;
      // list.forEach((_) => {
      //   if (this.form[_]) {
      //     count++;
      //   }
      // });
      for (const key in this.form) {
        if (Object.hasOwnProperty.call(this.form, key)) {
          const value = this.form[key];
          if (!!value) count++;
        }
      }
      if (this.form.password !== this.form.againPassword) {
        Toast(this.$t("Hint.pwdInconformity")); // 密码不一致
      } else if (count < requireNum) {
        Toast(this.$t("Hint.notInput")); // 输入内容不完整
      } else {
        this.register();
      }
    },
    handleLookPass(item) {
      this.formList.forEach((_) => {
        if (item.key === _.key) {
          if (_.type === "password") {
            _.type = "text";
            _.flag = true;
          } else {
            _.type = "password";
            _.flag = false;
          }
        }
      });
    },
    /** api **/
    register() {
      let form = { ...this.form };
      form.cmd = "100001";
      form.phone = getNewPhone(this.form.phone);
      // form.phone = this.form.phone
      form.pwd = md5(this.form.password);
      form.nickname = this.form.userName;
      form.version = Modelu.VersionEvent();
      form.introducer = this.form.referrer;
      form.channel = Modelu.signStrFunc();
      delete form.againPassword;
      delete form.password;
      delete form.referrer;
      // this.$store.commit("SET_LOGIN_INFO", {
      //   phone: form.phone,
      //   nickname: this.form.userName,
      //   version: Modelu.VersionEvent(),
      //   introducer: this.form.referrer,
      //   channel: Modelu.signStrFunc(),
      //   pwd: form.pwd,
      // });
      let item = {
        userphone: this.form.phone,
        userpass: this.form.password,
        code: "112233",
        fromid: this.form.referrer,
        paypass: this.form.paypass,
        username: this.form.username,
        country_code: this.areaCode,
        currency_code: this.form.currency_code,
        channel: getCookieValue('channel') || baseEncode()
      }; 
      const emailReg =
        /^[_a-zA-Z0-9-]+(\.[_a-zA-Z0-9-]+)*@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*(\.[a-zA-Z]{2,})$/;
      if (this.loginType === "email") {
        item.userphone = this.form.email;
        item.country_code = "";
        if (!emailReg.test(item.userphone)) {
          Toast(this.$t("emailFormatError"));
          return;
        }
      }
      if (this.$cookie.get("fromid")) {
        item.fromid = this.$cookie.get("fromid");
      }
      if (this.verConfig.registerHasRead) {
        if (!this.userAgreementChecked) {
          return Toast(this.$t("hint.loginNotRead"));
        }
      }
      // this.LoadingShow = true;
      this.$store.dispatch("login", item);
      // LOGIN(item)
      //   .then((r) => {
      //     this.LoadingShow = false;
      //     this.$store.commit("SET_LOGIN_INFO", {
      //       phone: getNewPhone(this.form.userName),
      //       pwd: this.form.password,
      //       channel: Modelu.signStrFunc(),
      //     });
      //     if (r.data.ret === 1) {
      //       this.$cookie.set("userInfo", JSON.stringify(r.data.data));
      //       this.$cookie.set("token", r.data.data.token);
      //       if (this.$cookie.get("fromid")) {
      //         this.$cookie.remove("fromid");
      //       }
      //       this.$router.replace("goods");
      //     } else {
      //       Toast(r.data.msg);
      //     }
      //   })
      //   .catch((e) => {
      //     this.LoadingShow = false;
      //     console.log(e);
      //   });
    },
    /* 其他 */
    setFromId() {
      let fromid = this.$route.query.id || this.$route.query.fromid;
      if (fromid) {
        this.$cookie.set("fromid", fromid);
      }
    },
  },
};
</script>

<style lang="less" scoped>
@Color: var(--theme);
@background: var(--nav-background);
@bg: var(--bg);
@sizeColor: var(--sizeColor);
@btnColor: var(--btnColor);
.wrap {
  min-height: calc(100vh - (30rem / 16));
  background-image: url('../../assets/Img/login/login_bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: calc(15rem / 16);
  overflow: hidden;
  position: relative;
  .top_title {
    margin-top: calc(8rem / 16);
    padding: calc(32rem / 16) 0;
    padding-left: 0;
    font-size: calc(26rem / 16);
    color: var(--theme);
    display: flex;
    justify-content: center;

    .title2 {
      margin-top: calc(14rem / 16);
      color: var(--theme);
    }
    img{
      height: calc(54rem / 16);
    }
  }

  .form_bar {
    background: @bg;
    border-radius: calc(12rem / 16);
    padding: calc(18rem / 16) calc(14rem / 16);
    .inputs {
      margin-top: calc(20rem / 16);
      > .border-bottom-calss {
        border-bottom: 0.0625rem solid #cbcbcb;
        > .iconfont {
          // color: var(--color, orangered);
          font-size: calc(22rem / 16);
          padding-right: calc(6rem / 16);
          color: var(--iconColor);
          // font-weight: 600;
        }
      }
      > div {
        display: flex;
        align-items: center;
        padding: 0.625rem calc(10rem / 16);
        span{
          color: @sizeColor;
        }

        > img:nth-child(1) {
          width: calc(18rem / 16);
          height: calc(18rem / 16);
          margin-right: calc(8rem / 16);
        }

        > div {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          input {
            // width: calc(220rem / 16);
            flex-grow: 1;
            border: 0;
            padding-left: calc(5rem / 16);
            font-size: calc(15rem / 16);
            // color: #000;
            color: @Color;
            background-color: transparent;
          }

          > img:nth-last-child(1) {
            width: calc(30rem / 16);
            margin-right: calc(15rem / 16);
          }
          .pwd_icons {
            font-size: calc(28rem / 16);
            color: var(--color, #333);
          }
        }
      }

      > div:nth-child(1) {
        // margin-bottom: calc(26rem / 16);
      }
      > div {
        margin-bottom: calc(12rem / 16);
      }

      > button {
        width: 100%;
        // margin-top: calc(40rem / 16);
        color: #ffffff;
        font-size: calc(15rem / 16);
      }
    }
    .checkbox {
      font-size: calc(12rem / 16);
      margin-top: calc(24rem / 16);
      margin-left: calc(4rem / 16);
    }
    .van-checkbox__label {
      color: #88888d !important;
    }
    .user_agreement {
      display: flex;
      align-items: center;
      margin-left: calc(4rem / 16);
      margin-top: calc(20rem / 16);
      .checkbox {
        width: fit-content !important;
      }
      .link {
        font-size: calc(12rem / 16);
        color: #323232;
        text-decoration: underline;
        margin-left: 8px;
      }
    }
    @btn_height: calc(48rem / 16);
    @btn_fontFamily: PingFangSC-Medium;
    .btn_login {
      width: 100%;
      line-height: @btn_height;
      background: var(--newBtnBg);
      border-radius: calc(5rem / 16);
      text-align: center;
      font-size: calc(18rem / 16);
      // font-family: @btn_fontFamily;
      border: 1px solid var(--newBtnBg);
      color: var(--newTheme);
      margin-top: calc(20rem / 16);
    }
    .btn_registe {
      .btn_login();
      background: transparent;
      color: var(--newTheme);
      margin-top: calc(20rem / 16);
      border: 1px solid var(--newTheme);
    }
  }
  .service_bar {
    border-radius: calc(8rem / 16);
    padding: calc(13rem / 16) calc(16rem / 16);
    background: #fff;
    margin-top: calc(22rem / 16);
    display: flex;
    align-items: center;
    .iconfont {
      font-size: calc(38rem / 16);
      padding-right: calc(8rem / 16);
    }
    > .p {
      font-size: calc(16rem / 16);
      color: #0271dc;
      .p2 {
        margin-top: calc(4rem / 16);
      }
    }
  }
  .lan_bar {
    position: absolute;
    top: 6px;
    right: 6px;
  }
}
/deep/ .van-picker__title{
  color: @Color;
}
</style>
